




































import { Vue, Provide, Component } from "vue-property-decorator";
import Nav from "@/components/header.vue";
import moment from "moment";
import serve from "@/serve/mine";
import { Dialog, Toast } from "vant";
@Component({
  components: {
    Nav,
  },
})
export default class Dynamic extends Vue {
  page = 0;
  listData = [];
  @Provide() num = 0;
  @Provide() num2 = 0;
  @Provide() img = require("@/assets/img/goods.jpg");
  @Provide() loading = false; //是否处于加载状态
  @Provide() finished = true; //是否已加载完所有数据
  @Provide() isLoading = false; //是否处于下拉刷新状态
  created() {
    this.getData();
  }
  private goTo(val: string): void {
    this.$router.push(val);
  }
  async getData() {
    const res = await serve.getMessage({ type: 1, size: 10, page: this.page });
    this.listData = this.listData.concat(res.data.data.content);
    this.loading = false;
    this.isLoading = false;
    if (res.data.data.totalElements <= this.listData.length) {
      this.finished = true;
    } else {
      this.finished = false;
    }
  }
  delMessage(id: string): void {
    Dialog.confirm({
      message: "此操作将永久删除该信息，是否继续",
    })
      .then(() => {
        serve.delMessage(id).then((res) => {
          if (res.data.code === 0) {
            Toast.success("删除成功");
            this.listData = []
            this.getData();
          } else {
            Toast.fail(res.data.message);
          }
        });
      })
      .catch(() => {
        //取消
      });
  }
  async hasRead(item: object){
    const res = await serve.hasReadMsg(item['id'])
    if(res.data.code === 0){
      item['isRead'] = 1
      Toast.success('标记已读成功')
    }
  }
  private timeFormat(val: string | null): string {
    return moment(val).format("YYYY-MM-DD HH:mm:ss");
  }
  private onLoad(): void {
    // 上拉加载
    this.loading = true;
    this.page++;
    this.getData();
  }

  private onRefresh(): void {
    //下拉刷新
    this.listData = [];
    this.isLoading = true;
    this.page = 0;
    this.getData();
  }
}
